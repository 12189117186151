import React, {SyntheticEvent, useContext, useState} from "react";
import {Btn} from "../common/Btn";
import './Header.css'
import {SearchContext} from "../../contexts/Search.context";

export const Header = () => {
    const {search, setSearch} = useContext(SearchContext)
    const [inputVal, setInputVal] = useState(search)
    return (
        <header>
            <h1>
                <a href="/"><strong>Mega </strong>Ogłoszenia</a>
            </h1>
            <Btn to='/add' text='Dodaj ogłoszenie'/>
            <form className="search" onSubmit={
                (e: SyntheticEvent)=>{
                    e.preventDefault();
                    setSearch(inputVal)
                }}>
                <input type="text" value={inputVal} onChange={e=> setInputVal(e.target.value)}/>
                <Btn text='Szukaj'/>
            </form>
        </header>
    );
};

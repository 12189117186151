import React, {SyntheticEvent, useState} from "react";
import {Btn} from "../common/Btn";
import {geocode} from "./geocode";
import {apiUrl} from "../../config/api";

import './AddForm.css';

export const AddForm = () => {
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState('');
    const [form, setForm ] = useState({
        name: '',
        description: '',
        price: 0,
        url: '',
        address: '',
        secondUrl: '',
    });

    const sendAd = async (e: SyntheticEvent) =>{
        e.preventDefault();
        setLoading(true);
        try {
            const {lat, lon} =  await geocode(form.address)

            const res = await fetch(`${apiUrl}/ad`,{
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ...form,
                    lat,
                    lon,
                }),
            });
            const data = await res.json();
            setId(data.id)
        }
        finally {
            setLoading(false)
        }

    }
    const updateForm = (key: string, value: any) =>{
        setForm(form=>({
            ...form,
                [key]: value
        }));
    };

    if(loading){
        return <h2>Trwa dodawanie ogłoszenia..</h2>
    }
    if(id){
        return (
            <>
                <h2>Ogłoszenie o nazwie {form.name} zostało dodane pod id: {id}</h2>
                <p className='adConfirmView'>
                    <Btn to='/' text={'Powrót do mapy'}/>
                </p>
            </>)
    }
    return(
        <form action='' className='addForm' onSubmit={sendAd} >
            <h1>
                Dodawanie ogłoszenia
            </h1>
            <p>
                <label>Nazwa:
                    <input
                        type="text"
                        name='name'
                        maxLength={99}
                        required
                        value={form.name}
                        onChange={event => updateForm('name', event.target.value)}
                    />
                </label>
            </p>
            <p>
                <label>Opis:
                    <textarea
                        name='description'
                        maxLength={1000}
                        value={form.description}
                        onChange={event => updateForm('description', event.target.value)}
                    >
                    </textarea>
                </label>
            </p>
            <p>
                <label>Cena:
                    <input
                        type="number"
                        name='price'
                        min={0}
                        max={9999999}
                        required
                        value={form.price}
                        onChange={event => updateForm('price', Number(event.target.value))}
                    />
                    <small>Pozostaw cenę zero, aby nie wyświetlać ceny.</small>
                </label>
            </p>
            <p>
                <label>Adres URL:
                    <input
                        type="url"
                        name='url'
                        maxLength={100}
                        value={form.url}
                        onChange={event => updateForm('url', event.target.value)}
                        required
                    />
                </label>
            </p>
            <p>
                <label>Drugi adres URL:
                    <input
                        type="url"
                        name='secondUrl'
                        maxLength={100}
                        value={form.secondUrl}
                        onChange={event => updateForm('secondUrl', event.target.value)}
                    />
                </label>
            </p>
            <p>
                <label>Lokalizacja (format: ulica nr, miasto):
                    <input
                        type="text"
                        name='address'
                        maxLength={100}
                        required
                        value={form.address}
                        onChange={event => updateForm('address', event.target.value)}
                    />
                </label>
            </p>
            <Btn text='Zapisz'/>
        </form>
    )
}
